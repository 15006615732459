<app-header></app-header>
<bit-container>
  <div *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="sr-only">{{ "loading" | i18n }}</span>
  </div>
  <form
    *ngIf="provider && !loading"
    #form
    (ngSubmit)="submit()"
    [appApiAction]="formPromise"
    ngNativeValidate
  >
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="name">{{ "providerName" | i18n }}</label>
          <input
            id="name"
            class="form-control"
            type="text"
            name="Name"
            [(ngModel)]="provider.name"
            [disabled]="selfHosted"
          />
        </div>
        <div class="form-group">
          <label for="billingEmail">{{ "billingEmail" | i18n }}</label>
          <input
            id="billingEmail"
            class="form-control"
            type="text"
            name="BillingEmail"
            [(ngModel)]="provider.billingEmail"
            [disabled]="selfHosted"
          />
        </div>
      </div>
      <div class="col-6">
        <bit-avatar [text]="provider.name" [id]="provider.id" size="large"></bit-avatar>
      </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ "save" | i18n }}</span>
    </button>
  </form>

  <app-danger-zone *ngIf="enableDeleteProvider$ | async">
    <button type="button" bitButton buttonType="danger" (click)="deleteProvider()">
      {{ "deleteProvider" | i18n }}
    </button>
  </app-danger-zone>
</bit-container>
