<app-callout type="tip" title="{{ 'prerequisite' | i18n }}">
  {{ "requireSsoPolicyReq" | i18n }}
</app-callout>

<bit-form-control>
  <input type="checkbox" id="enabled" bitCheckbox [formControl]="enabled" />
  <bit-label>{{ "turnOn" | i18n }}</bit-label>
</bit-form-control>

<div [formGroup]="data">
  <div class="tw-grid tw-grid-cols-12 tw-gap-4">
    <bit-form-field class="tw-col-span-6 !tw-mb-0">
      <bit-label>{{ "maximumVaultTimeoutLabel" | i18n }}</bit-label>
      <input bitInput type="number" min="0" formControlName="hours" />
      <bit-hint>{{ "hours" | i18n }}</bit-hint>
    </bit-form-field>
    <bit-form-field class="tw-col-span-6 tw-self-end !tw-mb-0">
      <input bitInput type="number" min="0" max="59" formControlName="minutes" />
      <bit-hint>{{ "minutes" | i18n }}</bit-hint>
    </bit-form-field>
    <bit-form-field class="tw-col-span-6">
      <bit-label>{{ "vaultTimeoutAction" | i18n }}</bit-label>
      <bit-select formControlName="action">
        <bit-option
          *ngFor="let option of vaultTimeoutActionOptions"
          [value]="option.value"
          [label]="option.name"
        ></bit-option>
      </bit-select>
    </bit-form-field>
  </div>
</div>
